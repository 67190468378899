import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    jobList,
    deleteDelete,
    createJob,
    editJob,
    jobListById

} from "../../services/api";
import { secretKey } from "../../services/config";
import EncryptDecrypt from "../../utils/encrypt&decrypt";

const namespace = "jobs";

// Async thunk for fetching the Portal customer list
export const getJobsList = createAsyncThunk(
    `${namespace}/getJobsList`,
    async (
        { query = "", page = 0, limit = 0, sortby = "", order = "" },
        { rejectWithValue }
    ) => {
        try {
            const data = await jobList(query, page, limit, sortby, order);


            return JSON.parse(
                EncryptDecrypt.decryptdata(data?.data?.datas, secretKey)
            );
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for fetching the Portal Job by id
export const getJobByID = createAsyncThunk(
    `${namespace}/getJobByID`,
    async ({ editId }, { rejectWithValue }) => {
        try {
          
            
            const data = await jobListById({editId});
               
        
            
            return JSON.parse(
                EncryptDecrypt.decryptdata(data?.data?.datas, secretKey)
            );
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for adding a Portal customer
export const addJob = createAsyncThunk(
    `${namespace}/addJob`,
    async ({ postData }, { rejectWithValue }) => {
        try {
            const payload = EncryptDecrypt.encryptdata(postData, secretKey);
            

            const data = await createJob({ payload });
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for updating Portal Customer data
export const updateJob = createAsyncThunk(
    `${namespace}/updateJob`,
    async ({ postData, updateID }, { rejectWithValue }) => {
     

        try {
            const payload = EncryptDecrypt.encryptdata(postData, secretKey);
           

            const data = await editJob({ payload }, updateID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// Async thunk for deleting a Portal User group
export const deleteJob = createAsyncThunk(
    `${namespace}/deleteJob`,
    async ({ deleteID }, { rejectWithValue }) => {


        try {
            const data = await deleteDelete(deleteID);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

// User Group Slice
const jobSlice = createSlice({
    name: namespace,
    initialState: {
        jobLoading: false,
        jobListData: [], // Ensure this structure matches the expected format
        jobListError: false,
        jobListByIdData: [],
        jobListByIdError:false,
        addJobSuccess: false,
        addJobError: false,
        addJobErrorMessage: "",
        updateJobSuccess: false,
        updateJobError: false,
        updateJobErrorMessage: "",

        deleteJobSuccess: false,
        deleteJobError: false,
        deleteJobErrorMessage: "",
    },

    reducers: {
        resetSuccessFlag: (state, action) => {
            const { flag } = action.payload;
            state[flag] = false;
        },
    },

    extraReducers: (builder) => {
        builder
            // Handle getUserGroupList
            .addCase(getJobsList.pending, (state) => {
                state.jobLoading = true;
            })
            .addCase(getJobsList.fulfilled, (state, action) => {
                const data = action.payload;
                 state.jobListData = data;
                 state.addJobSuccess = false;
                 
                  state.jobLoading = false;
            })

            .addCase(getJobsList.rejected, (state) => {
                state.jobLoading = false;
              
            })
            .addCase(getJobByID.pending, (state) => {
                state.jobLoading = true;
            })
            .addCase(getJobByID.fulfilled, (state, action) => {
                const data = action.payload;
                state.jobListByIdData = data;
                state.jobLoading = false;
            })

            .addCase(getJobByID.rejected, (state) => {
                state.jobLoading = false;
            })

            // Handle addCustoer
            .addCase(addJob.pending, (state) => {
                state.jobLoading = true;
                state.addJobError = false;
                state.addJobSuccess = false;
            })
            .addCase(addJob.fulfilled, (state) => {
                state.jobLoading = false;
                state.addJobSuccess = true;


            })
            .addCase(addJob.rejected, (state, action) => {
                state.jobLoading = false;
                state.addJobError = true;
             
                state.addJobErrorMessage =
                    action.payload?.data?.message || "An error occurred";
            })

            // Handle updateUserGroup
            .addCase(updateJob.pending, (state) => {
                state.jobLoading = false;
                state.updateJobError = false;
                state.updateJobErrorMessage = "";
            })
            .addCase(updateJob.fulfilled, (state) => {
                state.updateJobSuccess = true;
            })
            .addCase(updateJob.rejected, (state, action) => {
                state.updateJobError = true;
                state.updateJobErrorMessage =
                    action.payload?.data?.message || "An error occurred";
            })

            // Handle deleteUserGroup
            .addCase(deleteJob.pending, (state) => {
                state.jobLoading = true;
                state.deleteJobError = false;
                state.deleteJobErrorMessage = false;
            })
            .addCase(deleteJob.fulfilled, (state) => {
                state.jobLoading = false;
                state.deleteJobSuccess = true;
            })
            .addCase(deleteJob.rejected, (state, action) => {
                state.jobLoading = false;
                state.deleteJobError = true;
                state.deleteJobErrorMessage =
                    action.payload?.data?.message || "An error occurred";
            });
    },
});

// Export actions and selectors
export const { resetSuccessFlag } = jobSlice.actions;
export const jobSelector = (state) => state[namespace];
export default jobSlice.reducer;
