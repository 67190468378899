import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";

const SearchComponent = ({ className, placeHolder, onChange }) => {
   return (
      <Input
         className={`rounded rounded-4  ${className ? className : ""}`}
         placeholder={placeHolder}
         prefix={<SearchOutlined />}
         allowClear
         onChange={onChange}
      />
   );
};

export default SearchComponent;
