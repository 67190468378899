import { yupResolver } from "@hookform/resolvers/yup";
import { Card, Col, Row, Button, Flex,Space } from "antd";
import React, { useState } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import PageHeader from "../../view/pageheader";
import TextInput from "../CustomComponents/TextInput";
import SelectInput from "../CustomComponents/SelectInput";
import { FileDoneOutlined, RedoOutlined, PlusOutlined } from "@ant-design/icons";

const AddEditEvents = () => {
   const { name } = useParams();
   const validationRules = yup.object().shape({
      eventname: yup.string().required("Event Name is required"),
      eventtype: yup
         .number()
         .typeError("Please select a valid Event type")
         .required("Event type is required"),
      fieldvalue: watchFieldType == 1 || watchFieldType == 3
      ? yup.string().required("Field value is required")
      : yup.string().notRequired(),
      min: watchFieldType == 2 ? yup.number().required("Min is required") : yup.number().notRequired(),
      max: watchFieldType == 2 ? yup.number().required("Max is required") : yup.number().notRequired(),
   });
   
   const [fields, setFields] = useState([]);
   const [type, setType] = useState("add");

   const {
      control,
      handleSubmit,
      watch,
      formState: { errors },
      reset,
   } = useForm({
      resolver: yupResolver(validationRules),
      mode: "onChange",
   });
   var watchFieldType = useWatch({
      control,
      name: "eventtype"
   });

   const eventType = watch("eventtype");

   const handleAddField = () => {
      console.log( setFields([...fields, {id: Date.now(), value: "" }]),"uuu");
      
      setFields([...fields, {id: Date.now(), value: "" }]);
   };

   const handleRemoveField = (id) => {
      setFields(fields?.filter((field) => field?.id !== id));
   };

   const handleReset = () => {
      reset();
      setFields([]);
   };

   const onSubmit = (data) => {
      console.log("Form Submitted", data);
   };

   return (
      <>
            <PageHeader HeaderText="Events" Breadcrumb={[{ name: "Events" }]} backButtonUrl="" />
            <Card title="Add Event">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row gutter={[10, 0]} wrap className="p-2">
                        <Col span={12}>
                            <TextInput
                                name="eventname"
                                control={control}
                                label="Event Name"
                                placeholder="Enter your event name"
                                errors={errors}
                                required={true}
                            />
                        </Col>
                        <Col span={12}>
                            <SelectInput
                                name="eventtype"
                                control={control}
                                label="Event Type"
                                placeholder="Select event type"
                                options={[
                                    { label: "String", value: 1 },
                                    { label: "Number", value: 2 },
                                    { label: "List of value", value: 3 },
                                ]}
                                errors={errors}
                                required={true}
                            />
                        </Col>

                        {eventType === 1 || eventType === 3 ? (
                            <>
                                {fields.map((field, index) => (
                                    <Col span={12} key={field.id}>
                                        <Controller
                                            name={`fieldvalue[${index}]`}
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <TextInput
                                                    {...field}
                                                    label={`Field ${index + 1}`}
                                                    placeholder="Enter field value"
                                                    errors={errors}
                                                    required={true}
                                                />
                                            )}
                                        />
                                        <Button
                                            type="link"
                                            danger
                                            onClick={() => handleRemoveField(field.id)}
                                        >
                                            Remove
                                        </Button>
                                    </Col>
                                ))}
                                <Col span={12}>
                                    <Button
                                        type="dashed"
                                        onClick={handleAddField}
                                        icon={<PlusOutlined />}
                                    >
                                        Add Field
                                    </Button>
                                </Col>
                            </>
                        ) : eventType === 2 ? (
                            <>
                                <Col span={12}>
                                    <TextInput
                                        name="min"
                                        control={control}
                                        label="Min Value"
                                        placeholder="Enter minimum value"
                                        errors={errors}
                                        required={true}
                                    />
                                </Col>
                                <Col span={12}>
                                    <TextInput
                                        name="max"
                                        control={control}
                                        label="Max Value"
                                        placeholder="Enter maximum value"
                                        errors={errors}
                                        required={true}
                                    />
                                </Col>
                            </>
                        ) : null}
                    </Row>
                    <Space style={{ display: "flex", justifyContent: "center", marginTop: "16px" }}>
                        <Button
                            shape="round"
                            className="primary-btn"
                            icon={<FileDoneOutlined />}
                            htmlType="submit"
                        >
                            {type === "add" ? "Save" : "Update"}
                        </Button>
                        <Button
                            shape="round"
                            htmlType="button"
                            icon={<RedoOutlined />}
                            onClick={handleReset}
                        >
                            Reset
                        </Button>
                    </Space>
                </form>
            </Card>
        </>
   );
};

export default AddEditEvents;
