export const Leftmenu = () => {
   const roleID = localStorage.getItem("role_id");
   const currentUrl = window.location.href;

   console.log(roleID, "roleID==");

   const menus = [
      {
         menu: "Dashboard",
         url: "/dashboard",
         container: "dashboard-container",
         ischild: false,
         icon: "fa fa-home",
         child: [{ menu: "", url: "" }],
      },
      {
         menu: "User Groups",
         url: "/user-group",
         container: "user-groups-container",
         ischild: false,
         icon: "fa fa-users",
         child: [{ menu: "", url: "" }],
      },
      {
         menu: "User Management",
         url: "/user-management",
         container: "user-management-container",
         ischild: false,
         icon: "fa fa-address-book",
         child: [{ menu: "", url: "" }],
      },
      {
         menu: "Customers",
         url: "/customers",
         container: "customers-container",
         ischild: false,
         icon: "fa fa-handshake-o",
         child: [{ menu: "", url: "" }],
      },
      
      {
         menu: "Events",
         url: "/events",
         container: "events-container",
         ischild: false,
         icon: "fa fa-calendar",
         child: [{ menu: "", url: "" }],
      },
      {
         menu: "Workflow",
         url: "/workflow",
         container: "workflow-container",
         ischild: false,
         icon: "fa fa-sitemap",
         child: [{ menu: "", url: "" }],
      },
      {
         menu: "Jobs",
         url: "/jobs",
         container: "jobs-container",
         ischild: false,
         icon: "fa fa-briefcase",
         child: [{ menu: "", url: "" }],
      },
      {
         menu: "Reports",
         url: "/reports",
         container: "reports-container",
         ischild: false,
         icon: "fa fa-print",
         child: [{ menu: "", url: "" }],
      },
      {
         menu: "Barcode Generator",
         url: "/barcode",
         container: "barcode-container",
         ischild: false,
         icon: "fa fa-barcode",
         child: [{ menu: "", url: "" }],
      },
   ];

   const filteredMenus =
   roleID === "1" || roleID === "2"
      ? menus // Show all menus for role_id 1 and 2
      : roleID === "3"
      ? menus.filter(menu => ["Dashboard", "Jobs"].includes(menu.menu)) // Show only Dashboard and Jobs for role_id 3
      : [];

   return filteredMenus;
};
