import React from "react";
import { Form, Input } from "antd";
import { Controller } from "react-hook-form";

const { TextArea } = Input;

const TextAreaInput = ({ name, control, label, placeholder, errors, required }) => (
   <Form.Item
      layout="vertical"
      label={
         <span>
            {label}
            {required && (
               <span className="ms-1" style={{ color: "red" }}>
                  *
               </span>
            )}
         </span>
      }
      validateStatus={errors[name] ? "error" : ""}
      help={errors[name]?.message}
   >
      <Controller
         name={name}
         control={control}
         render={({ field }) => (
            <TextArea
               {...field}
               placeholder={placeholder}
               rows={3} // Default number of rows, customizable
               onChange={e => field.onChange(e.target.value)}
            />
         )}
      />
   </Form.Item>
);

export default TextAreaInput;
