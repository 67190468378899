import React, { forwardRef } from "react";
import { Controller } from "react-hook-form";
import { Input, Form } from "antd";

const TextInput = forwardRef(({ name, control, label, placeholder, errors, required }, ref) => (
   <Form.Item
      layout="vertical"
      label={
         <span>
            {label}
            {required && (
               <span className="ms-1" style={{ color: "red" }}>
                  *
               </span>
            )}
         </span>
      }
      validateStatus={errors[name] ? "error" : ""}
      help={errors[name]?.message}
   >
      <Controller
         name={name}
         control={control}
         render={({ field }) => (
            <Input
               {...field}
               ref={el => {
                  if (ref) ref.current = el;
                  field.ref(el);
               }}
               placeholder={placeholder}
               onChange={e => field.onChange(e)}
            //    style={{ marginTop: "8px" }}
            />
         )}
      />
   </Form.Item>
));

export default TextInput;
