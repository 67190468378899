import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
// import Logo from "../assets/images/horizon-full.svg";
import Logo from "../assets/images/scantek_logo.png";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
// import { ToastrContext } from "../utils/toastr";
import {
    resetPwd,
    loginSelector,
} from "../store/reducer/login";
import { API_STATUS } from "../utils/constants";

function ResetPassword() {
    const params = useParams();
    const dispatch = useDispatch();
    let navigate = useNavigate();
    // const toastr = useContext(ToastrContext);
    const [loginDetails, setLoginDetails] = useState({ token: params?.token, new_password: "", confirm_password: "" });
    const { loginData, resetloading, errorMessage } = useSelector(loginSelector);
    const passwordValidationRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;
    const onLoginSubmit = (e) => {
        e.preventDefault();
        if (loginDetails.new_password === "" || loginDetails.confirm_password === "") {
            toast.dismiss();
            return toast.error("Please enter Password and Confirm Password");

        }
        if (loginDetails.new_password !== loginDetails.confirm_password) {
            toast.dismiss();
            return toast.error("Password and Confirm Password should be same");

        }
        if (!passwordValidationRegex.test(loginDetails.new_password)) {
            toast.dismiss();
            return toast.error("Password should be alphanumeric, case sensitive with minimum of 12 characters");
            
        }

        let postData = loginDetails;
        dispatch(resetPwd({ postData }));

    };

    useEffect(() => {
        console.log(resetloading, "loading")
        if (resetloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Password resetted Successfully!");
            navigate('/login');
        }
        if (resetloading === API_STATUS.REJECTED) {
            toast.dismiss();
            toast.error(errorMessage?.message);

        }
    }, [resetloading]);

    return (
        <div className="theme-cyan">
            <div >
                <div className="vertical-align-wrap">
                    <div className="vertical-align-middle auth-main">
                        <div className="auth-box">
                            <div className="top">
                                <img src={Logo} alt="Lucid" style={{ height: "55px", width: "auto", margin: "10px" }} />
                            </div>
                            <div className="card">
                                <div className="header">
                                    <p className="lead">Recover my password</p>
                                </div>
                                <div className="body">
                                    <p>Please enter your password and confirm password for resetting password.</p>
                                    <form className="form-auth-small ng-untouched ng-pristine ng-valid">
                                        <div className="form-group">
                                            <input className="form-control" placeholder="New Password" type="password" value={loginDetails.password}
                                                onChange={val => {
                                                    setLoginDetails({ ...loginDetails, new_password: val.target.value });
                                                }} />
                                        </div>
                                        <div className="form-group">
                                            <input className="form-control" placeholder="Confirm Password" type="password" value={loginDetails.confirm_password}
                                                onChange={val => {
                                                    setLoginDetails({ ...loginDetails, confirm_password: val.target.value });
                                                }} />
                                                
                                        </div>
                                        <button className="btn btn-primary btn-lg btn-block" type="submit" onClick={(e) => { onLoginSubmit(e) }}>
                                            RESET PASSWORD
                                        </button>
                                        <div className="bottom">
                                            <span className="helper-text">Know your password? <a href={`${process.env.PUBLIC_URL}/`}>Login</a></span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;
