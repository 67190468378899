import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, Space, Tooltip } from "antd";
import { Link, useNavigate } from "react-router-dom";
import PageHeader from "../../view/pageheader";
import TableComponent from "../CustomComponents/TableComponent";

const Events = () => {
   const navigate = useNavigate();

   const eventData = [
      {
         id: "1",
         event_name: "Pre-Data Entry",
         created_by: "System",
         date_added: "2023-01-15",
         last_updated: "2023-08-01",
         status: true,
      },
      {
         id: "2",
         event_name: "Data Entry",
         created_by: "Alice Johnson",
         date_added: "2023-02-10",
         last_updated: "2023-08-15",
         status: true,
      },
      {
         id: "3",
         event_name: "Prep",
         created_by: "Bob Smith",
         date_added: "2023-03-05",
         last_updated: "2023-07-20",
         status: false,
      },
      {
         id: "4",
         event_name: "Scan",
         created_by: "System",
         date_added: "2023-04-12",
         last_updated: "2023-08-10",
         status: true,
      },
      {
         id: "5",
         event_name: "QC",
         created_by: "Diana Prince",
         date_added: "2023-05-18",
         last_updated: "2023-08-20",
         status: false,
      },
   ];

   const columns = [
      {
         title: "ID",
         dataIndex: "id",
         key: "id",
         align: "center",
         sorter: true,
      },
      {
         title: "Event Name",
         dataIndex: "event_name",
         key: "event_name",
         align: "center",
         sorter: true,
      },
      {
         title: "Created By",
         dataIndex: "created_by",
         key: "created_by",
         align: "center",
         sorter: true,
      },
      {
         title: "Date Added",
         dataIndex: "date_added",
         key: "date_added",
         align: "center",
         sorter: true,
      },
      {
         title: "Last Updated",
         dataIndex: "last_updated",
         key: "last_updated",
         align: "center",
         sorter: true,
      },
      {
         title: "Status",
         key: "status",
         align: "center",
         render: data =>
            data.status ? (
               <span style={{ color: "green" }}>Active</span>
            ) : (
               <span style={{ color: "red" }}>Inactive</span>
            ),
      },
      {
         title: "Action",
         key: "Action",
         align: "center",
         render: data => (
            <Space>
               <Tooltip title="Edit">
                  <Button
                     shape="circle"
                     className="border-0"
                     icon={<EditOutlined style={{ color: "blue" }} />}
                  />
               </Tooltip>
               <Tooltip title="Delete">
                  <Button
                     shape="circle"
                     className="border-0"
                     icon={<DeleteOutlined style={{ color: "red" }} />}
                  />
               </Tooltip>
            </Space>
         ),
      },
   ];

   return (
      <div>
         <PageHeader HeaderText="Events" Breadcrumb={[{ name: "Events" }]} backButtonUrl="" />
         <Card
            title="Events List"
            extra={
               <Link to="/events/add">
                  <Button className="primary-btn" shape="round">
                     Add
                  </Button>
               </Link>
            }
         >
            <TableComponent columns={columns} dataSource={eventData} size={"small"} />
         </Card>
      </div>
   );
};

export default Events;
