import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Col, Modal, Row, Space, Tooltip } from "antd";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import PageHeader from "../../view/pageheader";
import SelectInput from "../CustomComponents/SelectInput";
import TableComponent from "../CustomComponents/TableComponent";
import TextInput from "../CustomComponents/TextInput";

const WorkFlow = () => {
   const navigate = useNavigate();
   const workFlowSchema = {
      event_id: "",
      event_order: "",
      spec_id: "",
      status: "",
      workflow_id: "",
   };
   const validationRules = yup.object().shape({
      status: yup.number().typeError("Please select a valid status").required("Status is required"),
   });

   const {
      control,
      handleSubmit,
      formState: { errors },
      reset,
   } = useForm({
      resolver: yupResolver(validationRules),
      mode: "onChange", // Enable live validation
   });
   const [isAddModal, setIsAddModal] = useState(false);

   const workflowData = [
      {
         spec_id: "SPEC001",
         workflow_id: "WF001",
         event_id: "EVT001",
         event_order: "1",
         created_by: "UserA",
         date_added: "2024-01-15T10:00:00Z",
         last_updated: "2024-02-20T12:30:00Z",
         status: true,
      },
      {
         spec_id: "SPEC002",
         workflow_id: "WF002",
         event_id: "EVT002",
         event_order: "2",
         created_by: "UserB",
         date_added: "2024-03-10T09:15:00Z",
         last_updated: "2024-04-18T14:45:00Z",
         status: false,
      },
      {
         spec_id: "SPEC003",
         workflow_id: "WF003",
         event_id: "EVT003",
         event_order: "3",
         created_by: "UserC",
         date_added: "2024-05-05T11:20:00Z",
         last_updated: "2024-06-25T16:00:00Z",
         status: true,
      },
      {
         spec_id: "SPEC004",
         workflow_id: "WF004",
         event_id: "EVT004",
         event_order: "4",
         created_by: "UserD",
         date_added: "2024-07-12T08:50:00Z",
         last_updated: "2024-08-30T10:10:00Z",
         status: false,
      },
      {
         spec_id: "SPEC005",
         workflow_id: "WF005",
         event_id: "EVT005",
         event_order: "5",
         created_by: "UserE",
         date_added: "2024-09-22T13:35:00Z",
         last_updated: "2024-10-10T17:25:00Z",
         status: true,
      },
   ];

   const [userStatus] = useState([
      {
         label: "Active",
         value: 1,
      },
      {
         label: "Inactive",
         value: 2,
      },
   ]);

   const columns = [
      {
         title: "Spec ID",
         dataIndex: "spec_id",
         key: "spec_id",
         align: "center",
         sorter: true,
      },
      {
         title: "Workflow ID",
         dataIndex: "workflow_id",
         key: "workflow_id",
         align: "center",
         sorter: true,
      },
      {
         title: "Event ID",
         dataIndex: "event_id",
         key: "event_id",
         align: "center",
         sorter: true,
      },
      {
         title: "Created By",
         dataIndex: "created_by",
         key: "created_by",
         align: "center",
         sorter: true,
      },
      {
         title: "Date Added",
         dataIndex: "date_added",
         key: "date_added",
         align: "center",
         sorter: true,
      },
      {
         title: "Last Updated",
         dataIndex: "last_updated",
         key: "last_updated",
         align: "center",
         sorter: true,
      },
      {
         title: "Status",
         key: "status",
         align: "center",
         render: data =>
            data.status ? (
               <span style={{ color: "green" }}>Active</span>
            ) : (
               <span style={{ color: "red" }}>Inactive</span>
            ),
      },
      {
         title: "Action",
         key: "Action",
         align: "center",
         render: data => (
            <Space>
               <Tooltip title="Edit">
                  <Button
                     shape="circle"
                     className="border-0"
                     icon={<EditOutlined style={{ color: "blue" }} />}
                  />
               </Tooltip>
               <Tooltip title="Delete">
                  <Button
                     shape="circle"
                     className="border-0"
                     icon={<DeleteOutlined style={{ color: "red" }} />}
                  />
               </Tooltip>
            </Space>
         ),
      },
   ];

   const onSubmit = data => {
      console.log("Form Submitted", data);
   };

   const onModalClose = () => {
      reset();
      setIsAddModal(false);
   };

   const onModalOpen = () => {
      reset();
      setIsAddModal(true);
   };

   return (
      <div>
         <PageHeader HeaderText="Workflow" Breadcrumb={[{ name: "Workflow" }]} backButtonUrl="" />
         <Card
            title="Workflow List"
            extra={
               <>
                  <Button className="primary-btn" shape="round" onClick={onModalOpen}>
                     Add
                  </Button>
                  <Modal
                     title="Add Workflow"
                     centered
                     className="w-50"
                     open={isAddModal}
                     onOk={handleSubmit(onSubmit)}
                     onCancel={onModalClose}
                     okButtonProps={{ className: "primary-btn", shape: "round" }}
                     okText="Save"
                     cancelButtonProps={{ shape: "round" }}
                  >
                     <form onSubmit={handleSubmit(onSubmit)}>
                        <Row gutter={[10, 0]} wrap className="p-2">
                           <Col span={12}>
                              <SelectInput
                                 name="spec_id"
                                 control={control}
                                 label="Spec ID"
                                 placeholder="Select Spec ID"
                                 options={[{ label: "Spec001", value: 1 }]}
                                 errors={errors}
                                 required={true}
                              />
                           </Col>
                           <Col span={12}>
                              <TextInput
                                 name="workflow_id"
                                 control={control}
                                 label="Workflow ID"
                                 placeholder="Enter workflow ID"
                                 errors={errors}
                                 required={true}
                              />
                           </Col>
                           <Col span={12}>
                              <SelectInput
                                 name="event_id"
                                 control={control}
                                 label="Event ID"
                                 placeholder="Select Event ID"
                                 options={[{ label: "Eve001", value: 1 }]}
                                 errors={errors}
                                 required={true}
                              />
                           </Col>
                           <Col span={12}>
                              <SelectInput
                                 name="status"
                                 control={control}
                                 label="Status"
                                 placeholder="Select Status"
                                 options={userStatus}
                                 errors={errors}
                                 required={true}
                              />
                           </Col>
                           <Col span={12}>
                              <SelectInput
                                 name="order"
                                 control={control}
                                 label="Event Order"
                                 placeholder="Select Status"
                                 options={userStatus}
                                 errors={errors}
                                 required={true}
                              />
                           </Col>
                        </Row>
                     </form>
                  </Modal>
               </>
            }
         >
            <TableComponent columns={columns} dataSource={workflowData} size={"small"} />
         </Card>
      </div>
   );
};

export default WorkFlow;
