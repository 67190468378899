import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, Space, Tooltip } from "antd";
import { Link } from "react-router-dom";
import PageHeader from "../../view/pageheader";
import TableComponent from "../CustomComponents/TableComponent";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { resetSuccessFlag, deleteJob, getJobsList } from "../../store/reducer/jobSlice";
import { useEffect } from "react";
import SwalComponent from "../CustomComponents/SwalComponent";

const Jobs = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { jobListData, deleteJobSuccess } = useSelector((state) => state.jobs);

   const { count: totalCount, data: tableData } = jobListData;

   const role_id = localStorage.getItem("role_id");

   function getData(payload = {}) {
      dispatch(getJobsList(payload));
   }
   useEffect(() => {
      getData({})
      if (deleteJobSuccess) {
        
      
         toast.success("Job Deleted Successfully");
         dispatch(resetSuccessFlag({ flag: "deleteJobSuccess" }));
         
      }

   }, [deleteJobSuccess,dispatch])



   const columns = [
      // {
      //    title: "ID",
      //    dataIndex: "id",
      //    key: "id",
      //    align: "center",
      //    sorter: true,
      // },
      {
         title: "Job Name",
         dataIndex: "job_name",
         key: "job_name",
         align: "center",
         sorter: true,

      },
      {
         title: "Customer",
         dataIndex: 'customer',
         key: "customer",
         align: "center",
         sorter: true,
         render: data => (
            data?.customername ? data?.customername : "N/A"
         )
      },
      {
         title: "To",
         dataIndex: "user_role",
         key: "to",
         align: "center",
         sorter: true,
         render: data => (
            data?.user_name ? data?.user_name : "N/A"
            
            
         )
      },
      {
         title: "Pickup Date",
         dataIndex: "pickup_date",
         key: "created_by",
         align: "center",
         sorter: true,
         render: (date) => {
            const formattedDate = new Date(date).toLocaleDateString("en-GB"); // Formats date as dd/mm/yyyy
            return formattedDate;
         }
      },
      {
         title: "Spec ID",
         dataIndex: "spec_id",
         key: "spec_id",
         align: "center",
         sorter: true,
      },
      {
         title: "Workflow ID",
         dataIndex: "workflow_id",
         key: "workflow_id",
         align: "center",
         sorter: true,
      },
      
      {
         title: "Status",
         key: "status",
         align: "center",
         render: data =>
            data.status === 1 ? (
               <span style={{ color: "green" }}>Awaiting Review</span>
            ) : data.status === 2 ? (
               <span style={{ color: "red" }}>Yet to Start</span>
            ) : data.status === 3 ? (
               <span style={{ color: "blue" }}>In Progress</span>
            ) : data.status === 4 ? (
               <span style={{ color: "green" }}>Completed</span>
            ) : data.status === 5 ? (
               <span style={{ color: "red" }}>Return to Customer</span>
            ) : null,
      },
      {
         title: role_id == 1 || role_id == 2 ? "Action" : "",
         key: "Action",
         align: "center",
         render: data => {
           if (role_id == 1 || role_id == 2) {
             return (
               <Space>
                 <Tooltip title="Edit">
                   <Button
                     shape="circle"
                     className="border-0"
                     icon={
                       <EditOutlined
                         onClick={() => navigate(`/jobs/edit/${btoa(data?.id)}`)}
                         style={{ color: "blue" }}
                       />
                     }
                   />
                 </Tooltip>
                 <Tooltip title="Delete">
                   <SwalComponent
                     message="Are you sure you want to delete the Job?"
                     onClickDispatch={deleteJob}
                     id={data?.id}
                   />
                 </Tooltip>
               </Space>
             );
           }
           return null; // Return null if the condition is not met
         }
       }
       
   ];

   return (
      <div>
         <PageHeader HeaderText="Jobs" Breadcrumb={[{ name: "Jobs" }]} backButtonUrl="" />
         <Card
            title="Jobs List"
            extra={
               <Link to="/jobs/add">
                  <Button className="primary-btn" shape="round">
                     Add
                  </Button>
               </Link>
            }
         >
            <TableComponent columns={columns} dataSource={tableData} size={"small"} />
         </Card>
      </div>
   );
};

export default Jobs;
