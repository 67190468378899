import { FileDoneOutlined, RedoOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Col, Flex, Row } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { set, useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import * as yup from "yup";
import PageHeader from "../../view/pageheader";
import DateInput from "../CustomComponents/DateInput";
import SelectInput from "../CustomComponents/SelectInput";
import TextAreaInput from "../CustomComponents/TextAreaInput";
import TextInput from "../CustomComponents/TextInput";
import { getUserManagementList } from "../../store/reducer/userManagementSlice";
import { getCustomerList } from "../../store/reducer/customerSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addJob, updateJob, resetSuccessFlag, getJobByID } from "../../store/reducer/jobSlice";
import moment from "moment";
import dayjs from 'dayjs';

const AddEditJobs = () => {
   const { id } = useParams();

   // Get job ID from URL for editing
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const [type, setType] = useState('add'); // 'add' or 'edit'
   const [managerData, setManagerData] = useState([]);
   const [customerData, setCustomerData] = useState([]);
   const [jobData, setJobData] = useState({}); // Store job data for editing

   // Selectors
   const { addJobSuccess, updateJobSuccess, addJobError, addJobErrorMessage, jobListByIdData, updateJobErrorMessage, updateJobError } = useSelector(state => state.jobs);
// Form Validation Rules using Yup
   const validationRules = yup.object().shape({
      customer_id: yup.number().typeError("Please select a valid customer").required("Customer is required"),
      job_name: yup.string().trim().required("Job name is required"),
      address: yup.string().trim().required("Address is required"),
      aisle: yup.string().trim().required("Aisle is required"),
      level: yup.string().trim().required("Level is required"),
      bay: yup.string().trim().required("Bay is required"),
      status: yup.number().typeError("Please select a valid status").required("Status is required"),
      from: yup.string().trim().required("From is required"),
      to: yup.number().typeError("Please select a valid project manager").required("Project manager is required"),
      pickup_date: yup.date().required("Pickup date is required"),
      original_disposition: yup.number().typeError("Please select a valid original disposition").required("Original disposition is required"),
      disposition_day: yup.string().trim().required("Disposition day is required"),
      left_warehouse: yup.string().trim().required("Left warehouse is required"),
      volume: yup.string().trim().required("Volume is required"),
      spec_id: yup.string().trim().required("Spec ID is required"),
      workflow_id: yup.string().trim().required("Workflow ID is required"),
   });

   const { userManagementListData } = useSelector(state => state.userManagement);
   const { customerListData } = useSelector(state => state.customers);
   function getDataCustomer(payload = {}) {
      dispatch(getCustomerList(payload));
   }
   useEffect(() => {
      getDataCustomer();
   }, []);
   useEffect(() => {
      if (customerListData?.data) {

         setCustomerData(customerListData?.data);
      }
   }, [customerListData]);
   const customerList = useMemo(() => {
      return customerData.filter((item) => item?.status === 1).map((item) => ({
         label: item.customername,
         value: item.id
      }));
   }, [managerData]);
   function getDataProjectManager(payload = {}) {
      dispatch(getUserManagementList(payload));
   }
   useEffect(() => {
      getDataProjectManager();
   }, []);
   useEffect(() => {
      if (userManagementListData?.data) {

         setManagerData(userManagementListData?.data);
      }
   }, [userManagementListData]);
   const managerList = useMemo(() => {
      return managerData.filter((item) => item.role_id === 2&& item.status === 1).map((item) => ({
         label: item.user_name,
         value: item.id
      }));
   }, [managerData]);

   useEffect(() => {
      if (addJobSuccess) {
         toast.success("Job added successfully");
         dispatch(resetSuccessFlag({ flag: "addJobSuccess" }));
         navigate("/jobs");
      }
      if (updateJobSuccess) {
         toast.success("Job updated successfully");
         dispatch(resetSuccessFlag({ flag: "updateJobSuccess" }));
         navigate("/jobs");
      }
      if (addJobError) {
         toast.error(addJobErrorMessage);
         dispatch(resetSuccessFlag({ flag: "addJobError" }));
      }
      if (updateJobError) {
         toast.error(updateJobErrorMessage);
         dispatch(resetSuccessFlag({ flag: "updateJobError" }));
      }
   }, [
      addJobSuccess, 
      updateJobSuccess, 
      addJobError, 
      addJobErrorMessage, 
      updateJobError, 
      updateJobErrorMessage, 
      dispatch, 
      navigate
   ]);
   
   useEffect(() => {
      if (jobListByIdData) {
         setJobData(jobListByIdData?.data);
      }
   }, [jobListByIdData]);
   ;



   // Form hook setup
   const { control, handleSubmit, formState: { errors }, reset } = useForm({
      resolver: yupResolver(validationRules),
      mode: "onChange",
   });
  
   // Fetch job data for editing if job ID is available
   useEffect(() => {
      if (id) {
         let editId = atob(id)
         console.log(editId, "editId");


         setType('edit');
         dispatch(getJobByID({ editId })); // Fetch job data for editing

      }
   }, [id, dispatch]);
   console.log(jobData?.data, "JobData");

   useEffect(() => {
      if (type === "edit" && jobListByIdData) {
         const pickupDate = jobListByIdData?.data?.pickup_date;

         // Use dayjs() to parse the date, and use .isValid() method
         const parsedDate = pickupDate ? dayjs(pickupDate) : null;



         console.log(parsedDate, "Parsed Date");
         console.log(parsedDate?.isValid(), "Is Date Valid?");

         // Only proceed with resetting if the pickup_date is valid
         reset({
            ...jobListByIdData?.data,
            pickup_date: parsedDate && parsedDate.isValid() ? parsedDate.format("YYYY-MM-DD") : null,
         });
      }
   }, [type, jobListByIdData, reset]);

   const onSubmit = (data) => {


      if (type === 'add') {
         dispatch(addJob({ postData: data }));
      } else if (type === 'edit') {
         let editId = atob(id)
         dispatch(updateJob({ postData: data, updateID: editId }));
      }
   };


   const handleReset = () => {
      reset(); // Resets all fields to their default values
   };

   return (
      <div>
         <PageHeader HeaderText="Jobs" Breadcrumb={[{ name: "Jobs" }]} backButtonUrl="/jobs" />
         <Card title={type === 'add' ? "Add Job" : "Edit Job"}>
            <form onSubmit={handleSubmit(onSubmit)}>
               <Row gutter={[10, 0]} wrap className="p-2">
                  <Col span={8}>
                     <SelectInput
                        name="customer_id"
                        control={control}
                        label="Customer"
                        placeholder="Select customer"
                        options={customerList}
                        errors={errors}
                        required={true}
                     />
                  </Col>
                  <Col span={8}>
                     <TextInput
                        name="job_name"
                        control={control}
                        label="Job Name"
                        placeholder="Enter Job Name"
                        errors={errors}
                        required={true}
                     />
                  </Col>
                  <Col span={8}>
                     <TextAreaInput
                        name="address"
                        control={control}
                        label="Building or Address"
                        placeholder="Enter address"
                        errors={errors}
                        required={true}
                     />
                  </Col>
                  <Col span={8}>
                     <TextAreaInput
                        name="aisle"
                        control={control}
                        label="Aisle"
                        placeholder="Enter aisle"
                        errors={errors}
                        required={true}
                     />
                  </Col>
                  <Col span={8}>
                     <TextAreaInput
                        name="level"
                        control={control}
                        label="Level"
                        placeholder="Enter level"
                        errors={errors}
                        required={true}
                     />
                  </Col>
                  <Col span={8}>
                     <TextAreaInput
                        name="bay"
                        control={control}
                        label="Bay or Shelf"
                        placeholder="Enter shelf"
                        errors={errors}
                        required={true}
                     />
                  </Col>
                  <Col span={8}>
                     <TextAreaInput
                        name="from"
                        control={control}
                        label="From"
                        placeholder="Enter from"
                        errors={errors}
                        required={true}
                     />
                  </Col>
                  <Col span={8}>
                     <SelectInput
                        name="to"
                        control={control}
                        label="To"
                        placeholder="Select manager"
                        options={managerList}
                        errors={errors}
                        required={true}
                     />
                  </Col>
                  <Col span={8}>
                     <DateInput
                        name="pickup_date"
                        control={control}
                        label="Pickup Date"
                        placeholder="Select date"
                        format="YYYY-MM-DD"
                        errors={errors}
                        required={true}
                     />
                  </Col>
                  <Col span={8}>
                     <TextInput
                        name="spec_id"
                        control={control}
                        label="Spec ID"
                        placeholder="Enter your spec ID"
                        errors={errors}
                        required={true}
                     />
                  </Col>
                  <Col span={8}>
                     <TextInput
                        name="volume"
                        control={control}
                        label="Volume"
                        placeholder="Enter volume"
                        errors={errors}
                        required={true}
                     />
                  </Col>
                  <Col span={8}>
                     <TextInput
                        name="workflow_id"
                        control={control}
                        label="Workflow ID"
                        placeholder="Enter workflow ID"
                        errors={errors}
                        required={true}
                     />
                  </Col>
                  <Col span={8}>
                     <TextInput
                        name="left_warehouse"
                        control={control}
                        label="Left Warehouse"
                        placeholder="Enter left date"
                        errors={errors}
                        required={true}
                     />
                  </Col>
                  <Col span={8}>
                     <SelectInput
                        name="original_disposition"
                        control={control}
                        label="Original Disposition"
                        placeholder="Select disposition"
                        options={[{ label: "Return", value: 1 }, { label: "Hold", value: 2 }, { label: "Destruction", value: 3 }]}
                        errors={errors}
                        required={true}
                     />
                  </Col>
                  <Col span={8}>
                     <TextInput
                        name="disposition_day"
                        control={control}
                        label="Disposition Day"
                        placeholder="Enter disposition"
                        errors={errors}
                        required={true}
                     />
                  </Col>
                  <Col span={8}>
                     <SelectInput
                        name="status"
                        control={control}
                        label="Status"
                        placeholder="Select Status"
                        options={[{ label: "Awaiting Review", value: 1 }, { label: "Yet to Start", value: 2 }, { label: "In Progress", value: 3 }, { label: "Completed", value: 4 }, { label: "Return to Customer", value: 5 }]}
                        errors={errors}
                        required={true}
                     />
                  </Col>
               </Row>
               <Flex gap="small" justify="center">
                  <Button
                     shape="round"
                     className="primary-btn"
                     icon={<FileDoneOutlined />}
                     htmlType="submit"
                  >
                     {type === "add" ? "Save" : "Update"}
                  </Button>
                  <Button
                     shape="round"
                     htmlType="button"
                     icon={<RedoOutlined />}
                     onClick={handleReset}
                  >
                     Reset
                  </Button>
               </Flex>
            </form>
         </Card>
      </div>
   );
};

export default AddEditJobs;
