import React from "react";
import { Controller } from "react-hook-form";
import { Select, Form } from "antd";

const { Option } = Select;

const SelectInput = ({ name, control, label, placeholder, options, errors, required, onChange }) => (
   <Form.Item
      layout="vertical"
      label={
         <span>
            {label}
            {required && (
               <span className="ms-1" style={{ color: "red" }}>
                  *
               </span>
            )}
         </span>
      }
      validateStatus={errors[name] ? "error" : ""}
      help={errors[name]?.message}
   >
      <Controller
         name={name}
         control={control}
         render={({ field }) => (
            <Select
               {...field}
               placeholder={placeholder}
               defaultValue={""}
               showSearch
               optionFilterProp="children"
               filterOption={(input, option) =>
                  (option?.children || "").toLowerCase().includes(input.toLowerCase())
               }
               onChange={(value) => {
                  field.onChange(value); // Update react-hook-form's state
                  if (onChange) onChange(value); // Call the external onChange handler
               }}
            >
               <Option value="">Select</Option>
               {options?.map(option => (
                  <Option key={option.value} value={option.value}>
                     {option.label}
                  </Option>
               ))}
            </Select>
         )}
      />
   </Form.Item>
);

export default SelectInput;