import React from "react";
import { Button, Card, Col, Row } from "antd";
import { toast } from "react-toastify";
import PageHeader from "../view/pageheader";

const DashBoard = () => {
   return (
      <div>
         <PageHeader HeaderText="DashBoard" Breadcrumb={[{ name: "DashBoard" }]} backButtonUrl="" />
         <Card title="Jobs Status">
            <Row gutter={16}>
               <Col span={8}>
                  <Card type="inner" size="small" title="Total Boxes">
                     50
                  </Card>
               </Col>
               <Col span={8}>
                  <Card type="inner" size="small" title="In Progress Boxes">
                     20
                  </Card>
               </Col>
               <Col span={8}>
                  <Card type="inner" size="small" title="Completed Boxes">
                     30
                  </Card>
               </Col>
            </Row>
         </Card>
      </div>
   );
};

export default DashBoard;
