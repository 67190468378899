import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import Customers from "../components/Customers/Customers";
import DashBoard from "../components/DashBoard";
import AddEditEvents from "../components/Events/AddEditEvents";
import Events from "../components/Events/Events";
import Jobs from "../components/Jobs/Jobs";
import UserGroups from "../components/UserGroups/UserGroups";
import UserManagement from "../components/UserManagement/UserManagement";
import AddEditJobs from "../components/Jobs/AddEditJobs";
import WorkFlow from "../components/WorkFlow/Workflow";

const AdminRoutes = () => {
   return (
      <Routes>
         <Route path="/" element={<Outlet />}>
            <Route path="dashboard" element={<DashBoard />} />
            <Route path="user-group" element={<UserGroups />} />
            <Route path="user-management" element={<UserManagement />} />
            <Route path="customers" element={<Customers />} />
            <Route path="events" element={<Outlet />}>
               <Route index element={<Events />} />
               <Route path="add" element={<AddEditEvents />} />
               <Route path="edit/:event_id" element={<AddEditEvents />} />
            </Route>
            <Route path="jobs" element={<Outlet />}>
               <Route index element={<Jobs />} />
               <Route path="add" element={<AddEditJobs />} />
               <Route path="edit/:id" element={<AddEditJobs />} />
               {/* <Route path="edit/:event_id" element={<AddEditEvents />} /> */}
            </Route>
            <Route path="workflow" element={<WorkFlow />} />
         </Route>
      </Routes>
   );
};

export default AdminRoutes;
