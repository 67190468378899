import { configureStore } from "@reduxjs/toolkit";
import itemCategoriesReducer from "./reducer/itemCategoriesSlice";
import userGroupReducer from "./reducer/userGroupSlice";
import customerReducer from "./reducer/customerSlice";
import loginReducer from "./reducer/login";
import userManagementReducer from "./reducer/userManagementSlice";
import jobsReducer from "./reducer/jobSlice";
// import userReducer from './userSlice'
// import postReducer from './postsSlice'

const store = configureStore({
  reducer: {
    login: loginReducer,
    itemCategories: itemCategoriesReducer,
    userGroups: userGroupReducer,
    customers: customerReducer,
    userManagement: userManagementReducer,
    jobs: jobsReducer

    // posts: postReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
