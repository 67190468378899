import React from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import DashBoard from "../components/DashBoard";
import Jobs from "../components/Jobs/Jobs";

const UserRoutes = () => {
   return (
      <Routes>
         <Route path="/" element={<Outlet />}>
            <Route path="dashboard" element={<DashBoard />} />
            <Route path="jobs" element={<Jobs />} />
         </Route>
      </Routes>
   );
};

export default UserRoutes;
