import React from "react";
import AdminRoutes from "./AdminRoutes";
import ManagerRoutes from "./ManagerRoutes";
import UserRoutes from "./UserRoutes";

const MainRoutes = () => {
   const role = localStorage.getItem("role_id");

   if (role == 1 || role == 2) {
      return <AdminRoutes />;
   }
   // if (role == 2) {
   //    return <ManagerRoutes />;
   // }
   if (role == 3) {
      return <UserRoutes />;
   }
};

export default MainRoutes;
