import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import Logo from "../assets/images/scantek_logo.png";
// import { ToastrContext } from "../utils/toastr";
import {
    forgotPwd,
    loginSelector,
} from "../store/reducer/login";
import { API_STATUS } from "../utils/constants";

function ForgotPassword() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    // const toastr = useContext(ToastrContext);
    const [loginDetails, setLoginDetails] = useState({ email: "" });
    const { loginData, forgotloading,errorMessage } = useSelector(loginSelector);
    const [loading, setshowLoading] = useState(false)
    const onLoginSubmit = (e) => {
        setshowLoading(true);
        let postData = loginDetails;
        dispatch(forgotPwd({ postData }));
    };

    useEffect(() => {
        console.log(forgotloading, "loading")
        if (forgotloading === API_STATUS.FULFILLED) {
            setshowLoading(false);
            toast.dismiss();
            toast.success("Reset Password link sent successfully!");
            navigate('/');
        }
        if (forgotloading === API_STATUS.REJECTED) {
            setshowLoading(false);
            toast.dismiss();
            toast.error(errorMessage?.message);
        }
    }, [forgotloading]);

    return (
        <div className="theme-cyan">
            <div className={(loading)?"page-loader-wrapper":"page-loader-wrapper hide"} >
                <div className="loader">
                    <div className="m-t-30"><img src={Logo} width="auto" height="55" alt="Dipmenu" /></div>
                    <p>Please wait...</p>
                </div>
            </div>
            <div >
                <div className="vertical-align-wrap">
                    <div className="vertical-align-middle auth-main">
                        <div className="auth-box">
                            <div className="top">
                                <img src={Logo} alt="Lucid" style={{ height: "55px", width: "auto", margin: "10px" }} />
                            </div>
                            <div className="card">
                                <div className="header">
                                    <p className="lead">Recover my password</p>
                                </div>
                                <div className="body">
                                    <p>Please enter your email address below to receive instructions for resetting password.</p>
                                    <form className="form-auth-small ng-untouched ng-pristine ng-valid">
                                        <div className="form-group">
                                            <input className="form-control" placeholder="Email" type="email" value={loginDetails.email}
                                                onChange={val => {
                                                    setLoginDetails({ ...loginDetails, email: val.target.value });
                                                }} />
                                        </div>
                                        <button className="btn btn-primary btn-lg btn-block" type="submit" onClick={(e) => { e.preventDefault();onLoginSubmit(e) }}>
                                            RESET PASSWORD
                                        </button>
                                        <div className="bottom">
                                            <span className="helper-text">Know your password? <a href={`${process.env.PUBLIC_URL}/`}>Login</a></span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;
