import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  userGroupList,
  userGroupDelete,
  createUserGroup,
  editItemUserGroup,
  userGroupStatusUpdate,
} from "../../services/api";
import { secretKey } from "../../services/config";
import EncryptDecrypt from "../../utils/encrypt&decrypt";

const namespace = "userGroups";

// Async thunk for fetching the Portal User group list
export const getUserGroupList = createAsyncThunk(
  `${namespace}/getUserGroupList`,
  async (
    { query = "", page = 0, limit = 0, sortby = "", order = "" },
    { rejectWithValue }
  ) => {
    try {
      const data = await userGroupList(query, page, limit, sortby, order);

      return JSON.parse(
        EncryptDecrypt.decryptdata(data?.data?.datas, secretKey)
      );
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// Async thunk for adding a Portal User group
export const addUserGroup = createAsyncThunk(
  `${namespace}/addUserGroup`,
  async ({ postData }, { rejectWithValue }) => {
    try {
      const payload = EncryptDecrypt.encryptdata(postData, secretKey);
      console.log(payload, "payload");
      
      const data = await createUserGroup({ payload });
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// Async thunk for updating Portal User group data
export const updateUserGroup = createAsyncThunk(
  `${namespace}/updateUserGroup`,
  async ({ postData, updateID }, { rejectWithValue }) => {
    console.log(updateID, "Updating User Group");
    console.log("asyncTHUNK", postData, updateID);
    
    
    try {
      const payload = EncryptDecrypt.encryptdata(postData, secretKey);
      console.log(payload, "payload");
      
      const data = await editItemUserGroup( {payload} , updateID);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// Async thunk for deleting a Portal User group
export const deleteUserGroup = createAsyncThunk(
  `${namespace}/deleteUserGroup`,
  async ({ deleteID }, { rejectWithValue }) => {
   
    
    try {
      const data = await userGroupDelete(deleteID);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

//update group status
export const updateGroupStatus = createAsyncThunk(
  `${namespace}/UpdateStatus`,
  async ({ postData, updateID }, { rejectWithValue }) => {
      try {
          let payload = EncryptDecrypt.encryptdata(postData, secretKey);
          const data = await userGroupStatusUpdate({ payload }, updateID);
          return data;
      } catch (error) {
          return rejectWithValue(error.response);
      }
  }
);

// User Group Slice
const userGroupSlice = createSlice({
  name: namespace,
  initialState: {
    userGroupLoading: false,
    userGroupListData: [], // Ensure this structure matches the expected format
    userGroupListError: false,
    addUserGroupSuccess: false,
    addUserGroupError: false,
    addUserGroupErrorMessage: "",
    updateUserGroupSuccess: false,
    updateUserGroupError: false,
    updateUserGroupErrorMessage: "",
    deleteUserGroupLoading: false,
    deleteUserGroupSuccess: false,
    deleteUserGroupError: false,
    deleteUserGroupErrorMessage: "",
    groupStatusSuccess: false,
    groupStatusError: false,
    groupStatusErrorMessage: "",
  },

  reducers: {
    resetSuccessFlag: (state, action) => {
      const { flag } = action.payload;
      state[flag] = false;
    },
  },

  extraReducers: (builder) => {
    builder
      // Handle getUserGroupList
      .addCase(getUserGroupList.pending, (state) => {
        state.userGroupLoading = true;
      })
      .addCase(getUserGroupList.fulfilled, (state, action) => {
        const data = action.payload;

        state.userGroupListData = data;

        state.userGroupLoading = false;
      })

      .addCase(getUserGroupList.rejected, (state) => {
        state.userGroupLoading = false;
      })

      // Handle addUserGroup
      .addCase(addUserGroup.pending, (state) => {
        state.userGroupLoading = true;
        state.addUserGroupError = false;
        state.addUserGroupSuccess = false;
      })
      .addCase(addUserGroup.fulfilled, (state) => {
        state.userGroupLoading = false;
        state.addUserGroupSuccess = true;
        
        
      })
      .addCase(addUserGroup.rejected, (state, action) => {
        state.userGroupLoading = false;
        state.addUserGroupError = true;
        state.addUserGroupErrorMessage =
          action.payload?.data?.message || "An error occurred";
      })

      // Handle updateUserGroup
      .addCase(updateUserGroup.pending, (state) => {
        state.updateUserGroupSuccess = false;
        state.updateUserGroupError = false;
        state.updateUserGroupErrorMessage = "";
      })
      .addCase(updateUserGroup.fulfilled, (state) => {
        state.updateUserGroupSuccess = true;
      })
      .addCase(updateUserGroup.rejected, (state, action) => {
        state.updateUserGroupError = true;
        state.updateUserGroupErrorMessage =
          action.payload?.data?.message || "An error occurred";
      })

      // Handle deleteUserGroup
      .addCase(deleteUserGroup.pending, (state) => {
        state.deleteUserGroupLoading = true;
        state.deleteUserGroupError = false;
        state.deleteUserGroupSuccess = false;
      })
      .addCase(deleteUserGroup.fulfilled, (state) => {
        state.deleteUserGroupLoading = false;
        state.deleteUserGroupSuccess = true;
      })
      .addCase(deleteUserGroup.rejected, (state, action) => {
        state.deleteUserGroupLoading = false;
        state.deleteUserGroupError = true;
        state.deleteUserGroupErrorMessage =
          action.payload?.data?.message || "An error occurred";
      })

      .addCase(updateGroupStatus.pending, (state) => {
        state.groupStatusSuccess = false;
        state.groupStatusError = false;
        state.groupStatusErrorMessage = "";
      })
      .addCase(updateGroupStatus.fulfilled, (state) => {
        state.groupStatusSuccess = true;
      })
      .addCase(updateGroupStatus.rejected, (state, action) => {
        state.groupStatusError = true;
        state.groupStatusErrorMessage =
          action.payload?.data?.message || "An error occurred";
      });
  },
});

// Export actions and selectors
export const { resetSuccessFlag } = userGroupSlice.actions;
export const userGroupSelector = (state) => state[namespace];
export default userGroupSlice.reducer;
